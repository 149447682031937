<template>
  <div>
    <footer class="main-footer">
      <div class="float-right d-none d-sm-inline">
        Anything you want
      </div>
      <strong
        >Copyright &copy; 2014-2018
        <a href="https://adminlte.io">AdminLTE.io</a>.</strong
      >
      All rights reserved.
    </footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
  component: {},
  props: {},
};
</script>
